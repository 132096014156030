$publicPath:'';

/deep/.el-tabs__nav-wrap::after {
  display: none;
}
/deep/.el-tabs__header {
  margin-left: 20px;
  margin-bottom: 0;
}
.my-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
